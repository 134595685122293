<template>
  <section class="page-ireland">
    <div class="banner">
      <img
        class="logo"
        src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/greece/icon.png"
      />
      <p class="title">
        <span>JWP Golden Greece Fund</span>
      </p>
    </div>
    <div class="content" :class="{ fold: isFold }">
      <p>
        JWP GREECE RAIF V.C.I.C. PLC, a RAIF under Part VIII of the Alternative
        Investments Funds Law 124(I)/2018, organized as an umbrella fund, has
        been entered into the list of Registered Alternative Investment Funds
        kept by the Cyprus Securities and Exchange Commission (“CySEC”) on the
        basis of an application submitted by BYRON CAPITAL PARTNERS LTD.
        <br /><br />
        As an immigration fund to assist foreign investor in acquiring Greece
        Golden Visa, aims to invest in real estate assets. The fund, currently
        under Initial Offer Period, draws huge attention from prospective
        investor soon after being published.
      </p>
    </div>
    <div class="look-more">
        <div class="opacity" v-if="isFold"></div>
        <div class="btn" @click="handleLookMore">
          <span>{{ btnText }}</span>
          <img
            :src="
              isFold
                ? '//static.hoolihome.com/global-jwp/assets/m/arrow-more-down.png'
                : '//static.hoolihome.com/global-jwp/assets/m/arrow-more-up.png'
            "
          />
        </div>
      </div>
  

    <div class="strategy">
      <p class="title">
        <span class="van-hairline--bottom">Investment Strategy</span>
      </p>
      <div class="list">
        <div class="item">
          <div class="name">Targeting Core/Core+ Assets</div>
          <div class="intro">
            JWP Golden Greece Fund targeting business is leading the recovery of
            Greece economy, such as hospitality and office, invests in mature
            properties that provide sufficient income covering the operating
            cost of the fund.
          </div>
        </div>
        <div class="item">
          <div class="name">No bank leverage</div>
          <div class="intro">
            Most private real estate funds take advantages of leverage to
            magnify the potential gains from investment while pushing investors
            to the edge of crisis. JWP Golden Greece Fund has no leverage, no
            senior debt or subordinated debt, uses no loans from bank.
            Investors, as shareholders will be the first to be paid back without
            bank leverage.
          </div>
        </div>
        <div class="item">
          <div class="name">Maximizing Capital Usage</div>
          <div class="intro">
            More than 80% of the total fund raised will be invested on average
            annually in order to increase the overall return of the fund.
          </div>
        </div>
      </div>
    </div>
    <div class="features">
      <p class="title">
        <span class="van-hairline--bottom">Fund Investment Act</span>
      </p>
      <van-image
        class="img"
        lazy-load
        src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/greece/part3-1_en.png"
      />
      <van-image
        class="img"
        lazy-load
        src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/greece/part3-2_en.png"
      />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isFold: true,
      btnText: "View More",
    };
  },
  beforeCreate() {
    document.title = "JWP Golden Greece Fund";
  },
  methods: {
    handleLookMore() {
      if (this.isFold) {
        this.isFold = false;
        this.btnText = "Collapse";
      } else {
        this.isFold = true;
        this.btnText = "View More";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page-ireland {
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 360px;
    background: url("//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/greece/bg.png")
      no-repeat center center / cover;
    .logo {
      width: 76px;
    }
    .title {
      margin-top: 20px;
      padding-bottom: 36px;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        border-bottom: 2px rgba(255, 255, 255, 0.5) solid;
      }
    }
  }
  .content {
    padding: 16px 22px 0;
    transition: all 0.3s;
    background: #fafafa;
    &.fold {
      height: 71px;
      overflow: hidden;
    }
    p {
      font-size: 11px;
      color: rgba(38, 38, 38, 1);
      line-height: 20px;
      text-align: justify;
    }
  }

  .look-more {
    padding: 20px 18px;
    background: #fafafa;
    z-index: 10;
    .opacity {
      position: absolute;
      height: 20px;
      width: 100% ;
      margin: -40px 0 0px -18px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fafafa);
      z-index: 10;    
    }
    .btn {
      margin: 0 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      border-radius: 4px;
      border: 1px solid rgba(38, 38, 38, 1);
      &:active {
        opacity: 0.8;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: rgba(38, 38, 38, 1);
      }
      img {
        width: 8px;
        margin-left: 8px;
      }
    }
  }

  .strategy {
    padding: 10px 0;

    .title {
      padding-bottom: 10px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .list {
      .item {
        margin: 10px 20px;
        padding: 16px 20px;
        background: #f3f8ff;
        border-radius: 8px;
        .name {
          margin-bottom: 16px;
          text-align: center;
          font-weight: 500;
          font-size: 12px;
          color: #0462c9;
          font-style: normal;
        }
        .intro {
          font-weight: 400;
          font-size: 11px;
          color: #666666;
          line-height: 16px;
          text-align: justify;
          font-style: normal;
        }
      }
    }
  }
  .features {
    padding: 20px 22px 0;
    text-align: center;
    background: #fafafa;
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .img {
      padding-bottom: 24px;
    }
  }
  .projects {
    padding: 40px 0 50px;
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .project {
      &:not(:last-child) {
        padding-bottom: 40px;
      }
      .hd {
        padding: 0 0 20px 22px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(38, 38, 38, 1);
        line-height: 25px;
      }
      .scroll-wrap {
        width: 100vw;
        overflow-x: scroll;
        .boxs {
          display: flex;
          padding-left: 22px;
          .box {
            flex-shrink: 0;
            padding-right: 14px;
            overflow-y: hidden;
            &:last-child {
              padding-right: 22px;
            }
            .van-image {
              overflow: hidden;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}
</style>
