var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page-ireland"},[_vm._m(0),_c('div',{staticClass:"content",class:{ fold: _vm.isFold }},[_vm._m(1)]),_c('div',{staticClass:"look-more"},[(_vm.isFold)?_c('div',{staticClass:"opacity"}):_vm._e(),_c('div',{staticClass:"btn",on:{"click":_vm.handleLookMore}},[_c('span',[_vm._v(_vm._s(_vm.btnText))]),_c('img',{attrs:{"src":_vm.isFold
              ? '//static.hoolihome.com/global-jwp/assets/m/arrow-more-down.png'
              : '//static.hoolihome.com/global-jwp/assets/m/arrow-more-up.png'}})])]),_vm._m(2),_c('div',{staticClass:"features"},[_vm._m(3),_c('van-image',{staticClass:"img",attrs:{"lazy-load":"","src":"//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/greece/part3-1_en.png"}}),_c('van-image',{staticClass:"img",attrs:{"lazy-load":"","src":"//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/greece/part3-2_en.png"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{staticClass:"logo",attrs:{"src":"//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/greece/icon.png"}}),_c('p',{staticClass:"title"},[_c('span',[_vm._v("JWP Golden Greece Fund")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" JWP GREECE RAIF V.C.I.C. PLC, a RAIF under Part VIII of the Alternative Investments Funds Law 124(I)/2018, organized as an umbrella fund, has been entered into the list of Registered Alternative Investment Funds kept by the Cyprus Securities and Exchange Commission (“CySEC”) on the basis of an application submitted by BYRON CAPITAL PARTNERS LTD. "),_c('br'),_c('br'),_vm._v(" As an immigration fund to assist foreign investor in acquiring Greece Golden Visa, aims to invest in real estate assets. The fund, currently under Initial Offer Period, draws huge attention from prospective investor soon after being published. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"strategy"},[_c('p',{staticClass:"title"},[_c('span',{staticClass:"van-hairline--bottom"},[_vm._v("Investment Strategy")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"name"},[_vm._v("Targeting Core/Core+ Assets")]),_c('div',{staticClass:"intro"},[_vm._v(" JWP Golden Greece Fund targeting business is leading the recovery of Greece economy, such as hospitality and office, invests in mature properties that provide sufficient income covering the operating cost of the fund. ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"name"},[_vm._v("No bank leverage")]),_c('div',{staticClass:"intro"},[_vm._v(" Most private real estate funds take advantages of leverage to magnify the potential gains from investment while pushing investors to the edge of crisis. JWP Golden Greece Fund has no leverage, no senior debt or subordinated debt, uses no loans from bank. Investors, as shareholders will be the first to be paid back without bank leverage. ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"name"},[_vm._v("Maximizing Capital Usage")]),_c('div',{staticClass:"intro"},[_vm._v(" More than 80% of the total fund raised will be invested on average annually in order to increase the overall return of the fund. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title"},[_c('span',{staticClass:"van-hairline--bottom"},[_vm._v("Fund Investment Act")])])
}]

export { render, staticRenderFns }